import { gtmFireEvent as trackingAction } from '~/helpers/gtm';
import { getPrompt as getSystemRolPrompt } from '../data/prompts/gpt3_5_turbo/_systemRol';
import * as Sentry from '@sentry/nextjs';

export const sendPrompt = (prompt) =>
  new Promise((resolve, reject) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 120000);

    const apiMessage = [
      { role: 'system', content: getSystemRolPrompt().promptContent },
      {
        role: 'user',
        content: prompt
      }
    ];

    const apiFunctions = [
      {
        name: 'process_video_data',
        description: 'Process and extract information from the provided video data',
        parameters: {
          type: 'object',
          properties: {
            videos: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  musicCategory: {
                    type: 'string',
                    description: 'The category of the music, e.g. Romantic'
                  },
                  scenes: {
                    type: 'array',
                    description:
                      'Array of scenes that contain information about the music personality',
                    items: {
                      type: 'object',
                      properties: {
                        voiceover: {
                          type: 'string',
                          description: 'Voiceover text for the scene'
                        }
                      }
                    }
                  },
                  meta: {
                    type: 'object',
                    description: 'Metadata for the video',
                    properties: {
                      title: {
                        type: 'string',
                        description: 'Title of the video'
                      },
                      description: {
                        type: 'string',
                        description: 'Description of the video'
                      },
                      hashtags: {
                        type: 'string',
                        description: 'Social media hashtags for the video'
                      },
                      mediaDescription: {
                        type: 'string',
                        description: 'Description of the media'
                      }
                    },
                    required: ['title', 'description', 'hashtags', 'mediaDescription']
                  }
                },
                required: ['musicCategory', 'scenes', 'meta']
              }
            }
          },
          required: ['videos']
        }
      }
    ];
    trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'request-api-openai'
    });
    fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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'
      },
      body: JSON.stringify({
        messages: apiMessage,
        functions: apiFunctions,
        model: 'gpt-3.5-turbo-0613',
        temperature: 0.7,
        max_tokens: 2500,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0
      }),
      signal: controller.signal
    })
      .then((response) => response.json())
      .then((result) => {
        clearTimeout(timeout);
        let responseMessage = result.choices[0].message;
        if (responseMessage.function_call) {
          const data = JSON.parse(responseMessage.function_call.arguments);
          resolve(data.videos);
        }
      })
      .catch((err) => {
        console.log('ERROR - api.openai - ', err);
        trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'error-api-openai',
          params: {
            error: `${err}`
          }
        });
        Sentry.captureException(err);
        reject(err);
      });
  });

export const sendMultiplePrompts = (messages) =>
  new Promise((resolve, reject) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 120000);

    trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'request-openai-api'
    });

    fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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'
      },
      body: JSON.stringify({
        messages: messages,
        model: 'gpt-3.5-turbo-0613',
        temperature: 0.7,
        max_tokens: 2500,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0
      }),
      signal: controller.signal
    })
      .then((response) => response.json())
      .then((result) => {
        clearTimeout(timeout);
        let responseMessage = result.choices[0].message.content;
        resolve(responseMessage);
      })
      .catch((err) => {
        console.log('ERROR - api.openai - ', err);
        trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'error-api-openai',
          params: {
            error: `${err}`
          }
        });
        Sentry.captureException(err);
        reject(err);
      });
  });
